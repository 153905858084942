<template>
  <validation-observer ref="simpleRules">
    <supplier-form />
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import SupplierForm from '@/views/Admin/Suppliers/SupplierForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Add',
  components: {
    SupplierForm,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
    saveData() {
      return this.$store.getters['suppliers/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
    this.$store.commit('suppliers/SET_DETAIL_FORM', true)
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('suppliers/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.id_countries) {
            this.$store.dispatch('cities/getDataList', {
              select: [
                'cities.id AS id',
                'cities.title AS title',
              ],
              where: {
                'cities.id_countries': res.id_countries,
              },
            })
          }
        })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('suppliers/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
